import Axios from 'axios'
import LRUCache from 'lru-cache'
import store from "@/storage";
import router from "@/router";
import {cacheAdapterEnhancer, throttleAdapterEnhancer} from "axios-extensions";
import Vue from "vue";

let axiosInstance = (() =>
    Axios.create({
        baseURL: '/',
        timeout: 300000, // 5 минут, так как генерация может быть долгой
        headers: { 'Cache-Control': 'no-cache' },
        adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(
            Axios.defaults.adapter,
            { enabledByDefault: false, defaultCache: new LRUCache({ maxAge: 1000*60, max: 100 })} //one-minute cache
        ))
    }))();

axiosInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${store.getters.getToken}`;
    return config;
});


axiosInstance.interceptors.response.use(
    (response) => {
        if (response.data.code === 102 && router.currentRoute.path !== "/signin") {
            console.error('Auth token is incorrect');
            Vue.toasted.error('Auth token is incorrect', { duration: 2000 });
            router.push('/signin');
        }
        return response;
    },
    (error) => {

        return Promise.reject(error);

});


export default axiosInstance;
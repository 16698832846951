import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    token: localStorage.getItem('user-token') || ''
};

const getters = {
    getToken: state => {
        return state.token;
    }
};

const mutations = {
    set_token: (state, token) => {
        state.token = token;
        localStorage.setItem('user-token', token);
    },

};

const actions = {
    setToken:(context, token) => {
        context.commit('set_token', token);
    },

    logout: () => {
        state.token = '';
        localStorage.removeItem('user-token');
    },
};

const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions
});

export default store;
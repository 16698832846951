<template class="flex-grow-1">
  <div style="height: 100%">


    <LoadingPopup :loading="loading" :seconds-passed="secondsPassed"></LoadingPopup>
    <b-sidebar id="sidebar-1" title="Sidebar" left no-header visible sidebar-class="left-info-panel" width="512px" z-index="1001">
      <b-container class="pt-3"  style="background: #FEFEFE;" >
        <div class="d-flex flex-row justify-content-between">
          <button class="btn-return" @click="$router.push('/')">
            <div class="info-text">
              <img src="../assets/icons/left_arrow.png" style="padding: 4px">
              <span class="ml-2 info-text" style="font-weight: bold; padding-left: 15px"> Сбросить и вернуться на начальный экран</span>
            </div>
          </button>
          <img src="../assets/icons/logo_itmo.png" style="width: 79px; height: 30px">
        </div>

        <h1 class="very-large-title-text mt-5 mb-5">Новый проект</h1>

        <div class="editor-section">
          <div v-b-toggle.accordion-2 class="d-flex flex-row align-items-center p-3">

            <div class="when-open selected-index large-title-text mr-2">1</div>
            <div class="when-closed non-selected-index large-title-text mr-2">1</div>

            <div class="large-title-text flex-grow-1" style="padding-left: 10px">Параметры генерации</div>
            <span class="when-open">  <img width="14" height="8" src="../assets/icons/up_arrow.png"></span>
            <span class="when-closed">  <img width="14" height="8" src="../assets/icons/down_arrow.png"></span>
          </div>

          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" >

            <div class="d-flex flex-column mb-3" style="align-items: flex-start;margin-left: 45px;">
              <div class="p-2 info-text" style="color: black">Выбор алгоритма</div>
              <div style="width: 408px; margin-left: 10px">
                <b-form-select v-model="selectedAlgorithm" :options="algorithmOptions"></b-form-select>
              </div>
            </div>

            <div class="d-flex flex-column mb-3" style="align-items: flex-start;margin-left: 45px; ">
              <div class="col-8">
                <div class="p-2 info-text" style="color: black">Предельная этажность</div>
              </div>
              <div class="col-4">
                <input class="float-start edit-values" type="number" id="floorInput" placeholder="Введите этажность" v-model="maxLevelsValue" max="40" min="2">
              </div>
            </div>
            <div v-if="selectedAlgorithm === 'SECTION'"  class="d-flex flex-column mb-3" style="align-items: flex-start;margin-left: 45px; ">
              <div class="col-8">
                <div class="p-2 info-text" style="color: black">Минимальная этажность</div>
              </div>
              <div class="col-4">
                <input class="float-start edit-values" type="number" id="floorInput" placeholder="Введите этажность" v-model="minLevelsValue" max="40" min="2">
              </div>
            </div>
            <div class="d-flex flex-column mb-3" style="align-items: flex-start;margin-left: 45px; ">
              <div class="col-8">
                <div class="p-2 info-text" style="color: black">Количество вариантов генерации</div>
              </div>
              <div class="col-4">
                <input class="float-start edit-values" type="number" min="1" max="5" id="variantsInput" placeholder="Введите количество вариантов от 1 до 5" v-model="variantsCount">
              </div>
            </div>
            <div class="d-flex flex-column mb-3" style="align-items: flex-start;margin-left: 45px; ">
              <div class="col-8">
                <div class="p-2 info-text" style="color: black">Коэффициент полезной площади МКД</div>
              </div>
              <div class="col-4">
                <input class="float-start edit-values" type="number" id="floorInput" placeholder="Введите минимальный коэффициент" v-model="targetAreaEfficiency" step="any">
              </div>
            </div>
            <div v-if="selectedAlgorithm === 'BUILDING'" class="d-flex flex-column mb-3" style="align-items: flex-start;margin-left: 45px; ">
              <div class="p-2 info-text" style="color: black">Библиотека зданий</div>
              <div style="width: 408px; margin-left: 10px">
                <b-form-select v-model="buildingLibraryId" :options="buildingLibraries"></b-form-select>
              </div>
            </div>
            <div v-if="selectedAlgorithm === 'BUILDING'" class="d-flex flex-column mb-3" style="align-items: flex-start;margin-left: 45px; ">
              <div class="p-2 info-text" style="color: black">Фиксированное здание</div>
              <div style="width: 408px; margin-left: 10px">
                <b-form-select v-model="buildingId" :options="buildings"></b-form-select>
              </div>
            </div>

            <div v-if="selectedAlgorithm === 'SECTION'" class="d-flex flex-column mb-3" style="align-items: flex-start;margin-left: 45px;">
              <div class="col-8">
                <div class="p-2 info-text" style="color: black">Максимальный GSI</div>
              </div>
              <div class="col-4">
                <input class="float-start edit-values" type="number" v-model="maxGSI" placeholder="Введите max GSI" max="1" min="0" step="0.1">
              </div>
            </div>

            <div v-if="selectedAlgorithm === 'SECTION'" class="d-flex flex-column mb-3" style="align-items: flex-start;margin-left: 45px;">
              <div class="col-8">
                <div class="p-2 info-text" style="color: black">Максимальный FSI</div>
              </div>
              <div class="col-4">
                <input class="float-start edit-values" type="number" v-model="maxFSI" placeholder="Введите max FSI" max="3" min="0" step="0.1">
              </div>
            </div>

            <div v-if="selectedAlgorithm === 'SECTION'" class="d-flex flex-column mb-3" style="align-items: flex-start;margin-left: 45px;">
              <div class="col-8">
                <div class="p-2 info-text" style="color: black">Максимальное количество секций в здании</div>
              </div>
              <div class="col-4">
                <input class="float-start edit-values" type="number" v-model="maxSectionsInBuilding" placeholder="Введите max секций" max="10" min="1" step="1">
              </div>
            </div>

            <div v-if="selectedAlgorithm === 'SECTION'" class="d-flex flex-column mb-3" style="align-items: flex-start;margin-left: 45px;">
              <div class="col-8">
                <div class="p-2 info-text" style="color: black">Максимальная длина секции</div>
              </div>
              <div class="col-4">
                <input
                    class="float-start edit-values"
                    type="text"
                    v-model="maxSectionLength"
                    :placeholder="maxSectionLength === null ? '' : 'Введите максимальную длину секции'"
                    @input="maxSectionLength = $event.target.value === '' ? null : $event.target.value">
              </div>
            </div>

            <div v-if="selectedAlgorithm === 'SECTION'" class="d-flex flex-column mb-3" style="align-items: flex-start; margin-left: 45px;">
              <div class="col-12">
                <div class="p-2 info-text" style="color: black">Выберите формы зданий</div>
              </div>
              <div class="col-12">
                <div class="d-flex flex-wrap justify-content-start">
                  <div v-for="shape in buildingShapeOptions" :key="shape.value" class="form-check d-flex align-items-center mr-4 mb-2">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        :id="shape.value"
                        :value="shape.value"
                        v-model="selectedBuildingShapes"
                        @change="validateBuildingShapes"
                    />
                    <label :for="shape.value" class="form-check-label ml-2 no-wrap">
                      {{ shape.name }}
                    </label>
                  </div>
                </div>

                <div v-if="showValidationError" class="text-danger d-block mt-2">
                  Необходимо выбрать хотя бы одну форму.
                </div>
              </div>
            </div>

            <div class="d-flex flex-column mb-3" style="align-items: flex-start; margin-left: 45px;">
              <div class="p-2 info-text" style=" color: black">Источник данных об окружении</div>
              <div style="margin-left: 10px; width: 408px;">
                <b-form-select v-model="surroundingMode">
                  <option value="OSM">Данные OpenStreetMap</option>
                  <option value="MOS">Платформа цифрового двойника Москвы</option>
                  <option value="NONE">Без окружения</option>
                </b-form-select>
              </div>
            </div>
            <div class="d-flex flex-column mb-3 mt-1" style="align-items: flex-start; margin-left: 55px;">
              <b-form-checkbox
                  id="insolationCheckbox"
                  v-model="calculateInsolation"
              >
                Учитывать инсоляцию
              </b-form-checkbox>
            </div>
            <div class="d-flex flex-column mb-3 mt-1" style="align-items: flex-start; margin-left: 55px;">
              <b-form-checkbox
                  id="parkingCheckbox"
                  v-model="calculateParking"
              >
                Включить генерацию паркинга
              </b-form-checkbox>
            </div>
          </b-collapse>
        </div>

        <div class="editor-section">
          <div v-b-toggle.accordion-3 class="d-flex flex-row align-items-center p-3">

            <div class="when-open selected-index large-title-text mr-2">2</div>
            <div class="when-closed non-selected-index large-title-text mr-2">2</div>

            <div class="large-title-text flex-grow-1" style="padding-left: 10px">Квартирография</div>
            <span class="when-open">  <img width="14" height="8" src="../assets/icons/up_arrow.png"></span>
            <span class="when-closed">  <img width="14" height="8" src="../assets/icons/down_arrow.png"></span>
          </div>

          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" >
            <div class="d-flex flex-column mb-3" style="align-items: flex-start; margin-left: 45px; margin-right: 15px">
              <div class="p-2 info-text" style="color: black">Расселяемые квартиры</div>
              <b-form-file

                  v-model="requiredApartmentsFile"
                  :state="Boolean(requiredApartmentsFile)"
                  @input="loadRequiredApartments"
                  accept=".xlsx"
                  :placeholder="filePlaceholder"
                  drop-placeholder="Перетащите сюда..."
              ></b-form-file>
              <div v-if="requiredApartments" class="p-2 info-text" style="color: black">
                {{requiredApartments.length}} квартир
              </div>
            </div>
            <div class="d-flex flex-column mb-3" style="align-items: flex-start; margin-left: 45px; margin-right: 15px">
              <div class="p-2 info-text" style="color: black">Дополнительные квартиры</div>
              <b-form-file
                  v-model="extraApartmentsFile"
                  :state="Boolean(extraApartmentsFile)"
                  @input="loadExtraApartments"
                  accept=".xlsx"
                  :placeholder="filePlaceholder"
                  drop-placeholder="Перетащите сюда..."
              ></b-form-file>
              <div v-if="extraApartments" class="p-2 info-text" style="color: black">
                {{extraApartments.length}} типов квартир
              </div>
            </div>
          </b-collapse>
        </div>

        <b-button
            :disabled="showValidationError || !isReady"
            @click="generate"
            class="mt-2 mb-2 generate-button">
          Запустить генерацию
        </b-button>

      </b-container>
    </b-sidebar>

    <editable-map ref="map"
                  editable
                  :zoom="zoom"
                  :maxZoom="18"
                  :center="center"
                  class="flex-grow-1"
                  :options="{attributionControl: false}"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-control-attribution position="bottomleft"></l-control-attribution>
      <l-geo-json :geojson="projectBounds" v-if="projectBounds" :options-style="{color: '#ff0000', fillOpacity: 0, dashArray: '10,5'}" ></l-geo-json>
      <l-control-scale position="topright" :maxWidth="200" :imperial="false" :metric="true"></l-control-scale>
    </editable-map>

  </div>
</template>

<script>
import {EditableMap} from "vue2-leaflet-editable";
import {LControlAttribution, LControlScale, LGeoJson, LTileLayer} from "vue2-leaflet";
import MapAPI from "@/mixins/MapAPI";
import LoadingPopup from "@/components/LoadingPopup";
import GeometryUtils from "@/mixins/GeometryUtils";
import {geoJSON, latLng} from "leaflet";
import Vue from "vue";


export default {
  name: "Editor",
  components: {
    EditableMap,
    LTileLayer,
    LControlAttribution,
    LControlScale,
    LGeoJson,
    LoadingPopup,
  },
  mixins: [
    MapAPI,
    GeometryUtils
  ],
  data() {
    return {
      projectArea: 0,
      surroundingMode: 'NONE',
      surroundings: [],
      filePlaceholder: "Выберите или перетащите файл",
      projectBounds: null,
      projectPolygon: null,
      buildingLibraries: [],
      buildings: [],
      buildingLibraryId: null,
      buildingId: null,
      calculateInsolation: false,
      calculateParking: false,
      requiredApartmentsFile: null,
      requiredApartments: null,
      extraApartmentsFile: null,
      extraApartments: null,
      generationParams: {},
      secondsPassed: 0,
      minLevelsValue: null,
      maxLevelsValue: 12,
      targetAreaEfficiency: 0.0,
      variantsCount: 1,
      secondsInterval: null,
      loading: false,
      taskId: null,
      interval: null,
      zoom: 15,
      center: latLng(55.763115, 37.622008),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      selectedAlgorithm: 'BUILDING',
      algorithmOptions: [
        { text: 'Building', value: 'BUILDING' },
        { text: 'Section', value: 'SECTION' }
      ],
      maxGSI: 0.4,
      maxFSI: 1.2,
      maxSectionsInBuilding: 5,
      maxSectionLength: null,
      selectedBuildingShapes: [],
      buildingShapeOptions: [
        { value: "TOWER", name: "Башня" },
        { value: "LINEAR", name: "Линейное" },
        { value: "L", name: "L-образное" },
        { value: "U", name: "U-образное" }
      ],
      showValidationError: false
    }
  },

  async mounted() {
    this.projectArea = this.$route.params.projectArea
    this.projectBounds = this.$route.params.projectGeometry
    this.projectPolygon = this.$route.params.projectPolygon

    this.selectedBuildingShapes = this.buildingShapeOptions.map(shape => shape.value);

    this.getBuildingLibraries().then(rz => {
      if (rz.data.code === 0) {
        let libraryOptions = rz.data.objects.map(o => {
          return {
            text: (o.name ? o.name : '<#' + o.id + ' без названия>'),
            value: o.id
          }
        });
        Vue.set(this, "buildingLibraries", libraryOptions)
        this.buildings = rz.data.objects.flatMap(lo => lo.buildings).map(b => {
          return {
            text: (b.uuid ? b.uuid : 'Здание ' + b.id),
            value: b.id
          }
        })
        this.buildings.push({
          text: "<Автоматический выбор>",
          value: null
        })
        this.buildingLibraryId = libraryOptions[0].value;
        console.log(this.buildingLibraries)
      } else {
        Vue.toasted.error('Error loading building libraries ' + rz.data.message, { duration: 2000 });
      }
    })

    let mapObject = this.$refs.map.mapObject
    mapObject.createPane("boundsPane")
    mapObject.getPane("boundsPane").style.zIndex = 200
    let g = geoJSON({type: "FeatureCollection", features: [this.projectBounds]});
    mapObject.fitBounds(g.getBounds())

  },

  methods: {
    validateBuildingShapes() {
      this.showValidationError = this.selectedBuildingShapes.length === 0;
    },

    async generate() {
      const areaLimits = await this.getAreaLimits();
      if (!areaLimits) {
        return;
      }

      //Проверка, что нарисованый полигон удовлетворяет требованиям, заданным на сервере
      let enteredArea = this.projectArea / 10000;
      if (enteredArea < areaLimits.data.minAreaHa || enteredArea > areaLimits.data.maxAreaHa) {
        this.$bvToast.toast("Отправленные параметры площади не совпадают с минимальными или максимальными параметрами на сервере", {
          title: 'Error',
          autoHideDelay: 10000,
          variant: "danger"
        });
        return;
      }

      //Ввод этажности генерируемого объекта
      let maxLevelsValue = parseInt(this.maxLevelsValue);

      if (isNaN(maxLevelsValue)) {
        this.$bvToast.toast("Пожалуйста, введите корректное значение максимальной этажности", {
          title: 'Error',
          autoHideDelay: 5000,
          variant: "danger"
        });
        return;
      }

      //Проверка максимального значения этажности
      if (this.maxLevelsValue > areaLimits.data.maxFloor || (this.maxLevelsValue < 2 && (this.maxLevelsValue !== '0'))){
        this.$bvToast.toast("Отправленный параметр этажности не совпадает с минимальным на сервере", {
          title: 'Error',
          autoHideDelay: 10000,
          variant: "danger"
        });
        return;
      }

      let minLevelsValue = null
      if (this.minLevelsValue) {
        minLevelsValue = parseInt(this.minLevelsValue)
        if (isNaN(minLevelsValue)) {
          this.$bvToast.toast("Пожалуйста, введите корректное значение минимальной этажности", {
            title: 'Error',
            autoHideDelay: 5000,
            variant: "danger"
          });
          return;
        }
        if (minLevelsValue > maxLevelsValue) {
          this.$bvToast.toast("Минимальная этажность не может быть больше максимальной", {
            title: 'Error',
            autoHideDelay: 5000,
            variant: "danger"
          });
          return;
        }
      }

      this.loading = true;
      this.secondsPassed = 0;
      this.secondsInterval = setInterval(() => {
        this.secondsPassed++;
      }, 1000)
      let request = {
        areaWGS84: "",
        maxLevels: maxLevelsValue,
        minLevels: minLevelsValue,
        variantsCount: this.variantsCount,
        requiredApartments: this.requiredApartments,
        apartmentTypes: this.extraApartments,
        calculateInsolation: this.calculateInsolation,
        calculateParking: this.calculateParking,
        surroundings: this.surroundingMode,
        targetAreaEfficiency: this.targetAreaEfficiency
      }
      if (this.projectBounds.geometry) {
        request.areaWGS84 = this.projectBounds.geometry;
      } else if (this.projectBounds.coordinates) {
        request.areaWGS84 = {
          type: "Polygon",
          coordinates: this.projectBounds.coordinates,
        };
      } else {
        console.error('Ошибка: projectBounds не содержит geometry или coordinates.');
      }
      request.algorithm = this.selectedAlgorithm
      if (this.selectedAlgorithm === 'BUILDING') {
        request.buildingLibraryId = this.buildingLibraryId;
        request.buildingId = this.buildingId;
        request.maxGSI = null;
        request.maxFSI = null;
        request.maxSectionsInBuilding = null;
      } else if (this.selectedAlgorithm === 'SECTION') {
        request.buildingLibraryId = null;
        request.buildingId = null;
        request.maxGSI = this.maxGSI;
        request.maxFSI = this.maxFSI;
        request.maxSectionsInBuilding = this.maxSectionsInBuilding;
        request.maxSectionLength = this.maxSectionLength;
        request.buildingShapes = this.selectedBuildingShapes
      }

      let self = this;
      this.generateConcept(request).then(rz => {
        if (rz.status === 200) {
          this.taskId = rz.data.data.id
          setTimeout(() => this.queryBuildingResult(), 3000);
          this.secondsPassed = 0;
        } else {
          console.log("error")
          this.showError(rz);
        }
      }).catch(function (error) {
        self.showError(error.response);
      })
    },

    showError(rz) {
      this.loading = false
      let message = "Произошла ошибка: "
      if (rz){
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
        if (this.secondsInterval) {
          clearInterval(this.secondsInterval);
          this.secondsInterval = null;
        }

        if (rz.status === 200) {
          message += rz.data.code
          if (rz.data.message) {
            message += ", " + rz.data.data.messages[0].message;
          }
        } else {
          this.showError(rz);
        }
      }
      else
        message += ", неопознанная ошибка"


      this.$bvToast.toast(message, {
        title: 'Error',
        autoHideDelay: 10000,
        variant: "danger"
      });
    },


    queryBuildingResult() {
      if(this.secondsPassed > 1400){
        this.loading = false;
        if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
        }
        if (this.secondsInterval) {
          clearInterval(this.secondsInterval);
          this.secondsInterval = null;
        }
        this.$bvToast.toast("Нет ответа от сервера или время истекло", {
          title: 'Error',
          autoHideDelay: 10000,
          variant: "danger"
        });
        return
      }
      this.getGenerationResult(this.taskId, 'STATUS').then(rz => {
        if (rz.status !== 200 || rz.data.data.state === "ERROR") {
          this.showError(rz);
        } else if (rz.status === 200 && rz.data.data.state === 'COMPLETED') {
          this.loading = false;
          if (this.secondsInterval) {
            clearInterval(this.secondsInterval);
            this.secondsInterval = null;
          }
          this.goToResult();
        } else {
          console.log("Not yet ready");
          setTimeout(() => this.queryBuildingResult(), 3000)
        }
      }).catch(error => {
        console.error("An error occurred:", error);
        this.loading = false;
        if (this.secondsInterval) {
          clearInterval(this.secondsInterval);
          this.secondsInterval = null;
        }
        this.$bvToast.toast(error.message, {
          title: 'Error',
          autoHideDelay: 10000,
          variant: "danger"
        });
      });
    },

    loadRequiredApartments(file) {
      this.requiredApartmentsFile = file
      console.log("loadRequiredApartments")
      console.log(this.requiredApartmentsFile)
      if (!this.requiredApartmentsFile) {
        return
      }
      this.processXLSXFile(this.requiredApartmentsFile).then(rz => {
        if (rz.data.code === 0) {
          this.requiredApartments = rz.data.objects
        } else {
          Vue.toasted.error('Error processing XLSX file ' + rz.data.message, { duration: 2000 });
        }
      })
    },

    loadExtraApartments(file) {
      this.extraApartmentsFile = file
      if (!this.extraApartmentsFile) {
        return;
      }
      this.processXLSXFile(this.extraApartmentsFile).then(rz => {
        if (rz.data.code === 0) {
          this.extraApartments = rz.data.objects
        } else {
          Vue.toasted.error('Error processing XLSX file ' + rz.data.message, { duration: 2000 });
        }
      })
    },

    goToResult() {
      this.$router.push({
        name: 'result',
        params: {
          resultId: this.taskId,
        }
      })
    },
  },

  computed: {
    isReady() {
      return !this.loading;
    },

    disabledHint() {
      if (this.loading) {
        return "Дождитесь окончания генерации"
      }
      return ""
    }
  },
}
</script>

<style scoped>
@import '../assets/styles/editor.css';
::placeholder{

  font-family: Mulish;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;

}

.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form-check-label {
  margin-left: 10px;
  white-space: nowrap;
}

</style>
<template>
  <div>
    <b-form @submit.prevent="login">
      <div class="authForm">

        <div class="d-flex flex-row justify-content-between align-items-center">
          <div class="welcomeText">Вход</div>
          <img src="../assets/icons/logo_itmo.png" style="opacity: 50%">
        </div>
        <div class="loginPassText">Токен</div>
        <div>
          <b-form-textarea class="inputForm" type="text" placeholder="Введите токен" id="token" v-model="token" required rows="8"  style="height: 160px;" />

          <b-button type="submit" class="buttonStyle" >Войти</b-button>
        </div>

      </div>
    </b-form>
  </div>
</template>

<script>
import MapAPI from "@/mixins/MapAPI";
import Vue from "vue";

export default {
  data() {
    return {
      token: '',
      showErrorMessage: false
    };
  },
  methods: {
    async login() {
      await this.$store.dispatch('setToken', this.token);

      MapAPI.methods.checkPing().then(rz => {
        if (rz.data.code === 0) {
          this.$router.push('/');
        } else {
          Vue.toasted.error('Auth token is incorrect', { duration: 2000 });
        }
      })

    },
  },
};
</script>

<style scoped>
.welcomeText {
  font-family: Mulish;
  font-size: 26px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0;
  text-align: start;
}

.authForm {
  width:  432px;
  height: 350px;
  margin: 150px auto 0 auto;
  padding: 32px 24px;
  border-radius: 12px;
  border: 2px;
  gap: 16px;
  box-shadow: 0px 6px 16px rgba(212, 218, 220, 0.64);
  backdrop-filter: blur(3px);
  background: #FFFFFFD6;
}

.loginPassText {
  font-family: Mulish;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 5px;
}

.inputForm {
  width:  388px;
  height:  40px;
  padding: 12px;
  border-radius: 12px;
  gap: 8px;
  border: 2px solid #EFF0EF;
}

.buttonStyle {
  margin-top: 15px;
  width: 388px;
  height: 48px;
  padding: 12px 26px;
  border-radius: 12px;
  gap: 12px;
  background: #2D2D2D;
}

.inputForm::placeholder {
  font-family: Mulish;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #5C6B70;
}

@font-face {
  font-family: "Mulish";
  src: url('../assets/fonts/Mulish-VariableFont_wght.ttf');
}
</style>

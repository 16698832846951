<template class="flex-grow-1" >
  <div style="height: 100%" @keydown.esc="hideBuildingInfo">

    <div class="logout-panel logout-panel title-text d-flex flex-row justify-content-around">
      <b-button class="btn-logout" @click="navigateToSignIn">Log Out</b-button>
    </div>

    <b-card v-if="areaGeometryInfoPopup == null && !generating && !error" class="map-info" style="width: 530px; height: 230px">
      <div class="d-flex flex-column align-items-center justify-content-around">
        <div>
          <b class="chooseParameters">Выберите участок</b>
        </div>


        <div v-if="drawingMode === 'none'" class="info-text mt-3" >
          Этот сервис генерирует проект жилья согласно программе реновации г.Москвы
          при помощи ИИ. Генерация учитывает выбранную область, окружение проекта и список загруженных квартир и позволяет
          быстро создать концепцию.
        </div>

        <div v-if="drawingMode === 'polygon'" class="info-text mt-3" >
          Последовательно отметьте на карте точки, чтобы
          очертить территорию. Для окончания ввода замкните
          линию, нажав на первую точку. Площадь должна быть
          в диапазоне от {{this.minAreaHa}} до {{this.maxAreaHa}} га.
        </div>

        <div class="version-text" v-if="version">
          {{ version }}
        </div>


        <div class="d-flex align-items-start">

          <div class="d-flex flex-row mt-3">
            <b-button v-if="drawingMode === 'none'"
                      variant="primary"
                      @click="startPolygon"
                      class="btn-building-primary">
              <img width="18" height="18" src="@/assets/icons/pen.png">
              Указать локацию
            </b-button>
            <b-button v-if="drawingMode !== 'none'" class="btn-building-load" variant="primary" @click="clearPolygon">Отмена</b-button>
            <b-button class="btn-building-load" @click="triggerFileUpload">Загрузить GeoJSON</b-button>
            <input type="file" id="geojson-upload" style="display: none" @change="handleGeoJSONUpload" accept=".geojson,.json" />
          </div>
        </div>
      </div>
    </b-card>
    <div class="map-container">
    <editable-map ref="map"
                  editable
                  :zoom="zoom"
                  :maxZoom="18"
                  :center="center"
                  class="flex-grow-1"
                  :options="{attributionControl: false, zoomControl: false}"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-geo-json :geojson="availableArea" v-if="availableArea" :options-style="{color: '#88ff88', fillOpacity: 0.1}" pane="availableZonePane"></l-geo-json>
      <l-control-scale position="topright" :maxWidth="200" :imperial="false" :metric="true"></l-control-scale>
    </editable-map>
    </div>
  </div>
</template>

<script>
import L, {latLng} from "leaflet";
import {LControlScale, LGeoJson, LTileLayer} from 'vue2-leaflet';
import {EditableMap} from "vue2-leaflet-editable";
import MapAPI from "../mixins/MapAPI.js";

import pencilIcon from "@/assets/pencil.svg";
import areaIcon from "@/assets/area.svg";
import GeometryUtils from "@/mixins/GeometryUtils";
import AuthorizationPage from "@/pages/AuthorizationPage";

export default {
  name: "Map.vue",
  components: {
    LTileLayer,
    LControlScale,
    EditableMap,
    LGeoJson
  },
  mixins: [ MapAPI, GeometryUtils ],
  data() {
    return {
      pencilIcon: pencilIcon,
      areaIcon: areaIcon,
      loading: false,
      zoom: 15,
      center: latLng(55.763115, 37.622008 ),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      fillColor: "#e4ce7f",
      availableArea: null,
      drawingMode: 'none', // none, polygon or point
      polygonName: '',
      polygonTags: {},
      polygonArea: 0.0,
      maxAreaHa: 0.0,
      minAreaHa: 0.0,
      polygonDrawing: false,
      polygonInfoPopup: null,
      jsonResponse: '',
      generating: false,
      areaGeometry: null,
      areaGeometryInfoPopup: null,
      error: null,
      version: "",
      timerEnabled: false,
      timerCount: 0,
      estimatedTime: 0,
      checkedPoly:true,
      showToast: false,

      idToLoad: ""
    };
  },
  methods: {
    triggerFileUpload() {
      document.getElementById('geojson-upload').click();
    },
    async handleGeoJSONUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      try {
        const text = await file.text();
        const geojson = JSON.parse(text);

        if (
            !geojson.type || geojson.type !== 'FeatureCollection' ||
            !geojson.features || geojson.features.length !== 1 ||
            geojson.features[0].geometry.type !== 'Polygon'
        ) {
          this.error = `Файл должен содержать одну Feature с типом Polygon.`
          this.$bvToast.toast(this.error, {
            title: 'Ошибка',
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000
          });

          this.clearPolygon();
        }

        this.loadGeoJSONToMap(geojson.features[0].geometry);
      } catch (error) {
        console.error(error);
        this.error = `Ошибка при загрузке GeoJSON: ${error.message}`;
        this.showToast = true;
        this.$bvToast.toast(this.error, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
          autoHideDelay: 3000
        });

        this.clearPolygon();
      }
    },
    loadGeoJSONToMap(geometry) {
      this.clearPolygon();

      const mapObject = this.$refs.map.mapObject;
      const geoJSONLayer = L.geoJSON(geometry, {
        style: { color: '#17A2B8' },
      });

      geoJSONLayer.addTo(mapObject);
      this.areaGeometry = geoJSONLayer.getLayers()[0];

      const bounds = this.areaGeometry.getBounds();
      mapObject.fitBounds(bounds);

      this.showPolygonPopup()

      this.areaGeometry.on('click', this.showPolygonPopup);
      this.areaGeometry.options.color = '#17A2B8';
    },

    areaText(m2) {
      if (m2 > 10000) {
        return (m2 / 10000).toFixed(2) + ' га (' + Number(m2.toFixed(0)).toLocaleString('ru-RU') + ' м<sup>2</sup>)';
      } else {
        return Number(m2.toFixed(2)).toLocaleString('ru-RU') + ' м<sup>2</sup>';
      }
    },
    navigateToSignIn() {
      this.$store.dispatch('logout');
      this.$router.push({ path: '/signin', component: AuthorizationPage });
    },

    countTimer() {
      setTimeout(() => {
        this.timerCount++;
        if (this.timerEnabled) {
          this.countTimer()
        }
      }, 1000);
    },
    generate() {
      this.$router.push({
        name: 'editor',
        params: {
          projectArea: this.polygonArea,
          projectGeometry: this.areaGeometry.toGeoJSON(),
          projectPolygon: this.areaGeometry
        }
      })
    },


    async startPolygon() {
      const areaLimits = await this.getAreaLimits();
      this.maxAreaHa = areaLimits.data.maxAreaHa
      this.minAreaHa = areaLimits.data.minAreaHa

      let mapObject = this.$refs.map.mapObject;
      this.areaGeometry = mapObject.editTools.startPolygon();
      this.areaGeometry.on('editable:drawing:commit', this.updateAreaAndContent);
      this.areaGeometry.on('click', this.showPolygonPopup);
      this.areaGeometry.on('editable:vertex:drag', this.updateAreaAndContent);
      this.areaGeometry.on('editable:drawing:commit', this.showPolygonPopup);
      this.areaGeometry.options.color = '#17A2B8';
      this.drawingMode = 'polygon';
      this.polygonDrawing = true;
    },

    clearPolygon() {
      let mapObject = this.$refs.map.mapObject;
      mapObject.editTools.stopDrawing();
      if (this.areaGeometry) {
        this.areaGeometry.remove()
      }
      this.areaGeometry = null;
      this.generating = false;
      this.timerEnabled = false;
      this.polygonDrawing = false;
      this.error = null;
      if (this.polygonInfoPopup != null) {
        this.polygonInfoPopup.remove();
        this.polygonInfoPopup = null;
      }
      this.drawingMode = 'none';
      if (this.areaGeometryInfoPopup != null) {
        this.areaGeometryInfoPopup.remove();
        this.areaGeometryInfoPopup = null;
      }

    },

    showPolygonPopup() {
      if (this.areaGeometryInfoPopup != null || this.generating) return;
      let context = this;
      this.areaGeometryInfoPopup = new L.Popup({
        closeOnClick: false
      }).setContent(
          this.popupSkeleton
      ).on("remove", function () {
        context.areaGeometryInfoPopup = null;
      });

      let mapObject = this.$refs.map.mapObject;
      mapObject.on('click', function() {
        context.updateAreaAndContent();
      });

      this.areaGeometryInfoPopup.on('popupopen', this.updateAreaAndContent);

      this.checkShape(this.polygonGeoJSON).then(async rz => {
        if (rz.data.code === 0) {
          this.polygonName = rz.data.name;
          this.polygonArea = rz.data.area;
          if (context.checkedTB) {
            this.polygonTags = rz.data.tags;
          }
          const areaLimits = await this.getAreaLimits();


          if (rz.data.area / 10000 > areaLimits.data.maxAreaHa) {
            this.areaGeometryInfoPopup.setContent(this.areaTooBigText);
            const btn = document.getElementById("popupButton"); //kind of hack can't call vue code from raw content. todo: alternatives
            btn.onclick = function (e) {
              e.preventDefault();
              context.clearPolygon();
            };
          } else if (rz.data.area / 10000 < areaLimits.data.minAreaHa) {
            this.areaGeometryInfoPopup.setContent(this.areaTooSmallText);
            const btn = document.getElementById("popupButton"); //kind of hack can't call vue code from raw content. todo: alternatives

            btn.onclick = function (e) {
              e.preventDefault();
              context.clearPolygon();
            };
          } else {
            this.areaGeometryInfoPopup.setContent(this.polygonPopupText);
            const btn = document.getElementById("popupButton");
            btn.onclick = function (e) {
              e.preventDefault();
              context.areaGeometry.disableEdit();
              context.generate();
            };
          }

          const closeButton = this.areaGeometryInfoPopup._closeButton;
          closeButton.addEventListener("click", function (e) {
            e.preventDefault();
            context.clearPolygon();
          });

        }
        else
          console.log(rz.data.code)
      });

      this.areaGeometryInfoPopup.setLatLng(this.areaGeometry.getBounds().getCenter());
      mapObject.addLayer(this.areaGeometryInfoPopup);
    },

    updateAreaAndContent() {
      if (!this.areaGeometry) return;

      let polygonGeoJSON = this.polygonGeoJSON;
      if (polygonGeoJSON.geometry.coordinates[0].length < 3) {
        return;
      }
      this.checkShape(polygonGeoJSON).then(async rz => {
        if (rz.data.code === 0) {
          this.polygonName = rz.data.name;
          this.polygonArea = rz.data.area;

          const areaLimits = await this.getAreaLimits();
          const context = this;

          if (rz.data.area / 10000 > areaLimits.data.maxAreaHa) {
            this.areaGeometryInfoPopup.setContent(this.areaTooBigText);
            const btn = document.getElementById("popupButton");
            btn.onclick = function (e) {
              e.preventDefault();
              context.clearPolygon();
            };
          } else if (rz.data.area / 10000 < areaLimits.data.minAreaHa) {
            this.areaGeometryInfoPopup.setContent(this.areaTooSmallText);
            const btn = document.getElementById("popupButton");
            btn.onclick = function (e) {
              e.preventDefault();
              context.clearPolygon();
            };
          } else {
            this.areaGeometryInfoPopup.setContent(this.polygonPopupText);
            const btn = document.getElementById("popupButton");
            btn.onclick = function (e) {
              e.preventDefault();
              context.areaGeometry.disableEdit();
              context.generate();
            };
          }
        }
      });
    },


  },
  computed: {
    polygonPopupText() {
      let rz = '<b class="title-area">' + this.polygonName + '</b>' +
          '<div class="area-parameters">' + this.areaText(this.polygonArea) + '</div>';
      rz += '<a href="#" id="popupButton" class="edit-button" >Перейти к вводу данных</a>';
      return rz;
    },

    areaTooBigText() {
      return '<b class="title-area">' + this.polygonName + '</b>' +
          '<div class="area-parameters">' + this.areaText(this.polygonArea) + '</div>'+
          '<div class="area-parameters" style="color: red">Ошибка! Полигон слишком большой!</div>' +
          '<a href="#" id="popupButton" class="edit-button">Сбросить полигон</a>';
    },
    areaTooSmallText() {
      return '<b class="title-area">' + this.polygonName + '</b>' +
          '<div class="area-parameters">' + this.areaText(this.polygonArea) + '</div>'+
          '<div class="area-parameters" style="color: red">Ошибка! Полигон слишком маленький!</div>' +
          '<a href="#" id="popupButton" class="edit-button">Сбросить полигон</a>';
    },
    popupSkeleton() {
      return '<div class="b-skeleton b-skeleton-text b-skeleton-animate-wave" style="width: 100px"></div>' +
          '<div class="b-skeleton b-skeleton-text b-skeleton-animate-wave"></div>' +
          '<br><a href="#" class="popup-button disabled"><span class="spinner-border spinner-border-sm"></span></a>';
    },
    isPolygonEmpty() {
      let polygon = this.polygonGeoJSON;
      return !polygon.geometry.coordinates
          || polygon.geometry.coordinates.length < 1
          || !polygon.geometry.coordinates[0]
          || polygon.geometry.coordinates[0].length < 2;
    },

    /**
     * Swaps lonlat coord order to latlon which is expected by backend
     */
    polygonGeoJSON() {
      let geoJson = this.areaGeometry.toGeoJSON()
      return this.invertPolygonCoords(geoJson)
    }
  },
  async mounted() {
    const versionData = await this.checkVersion();
    if (versionData.data.code === 0) {
      this.version = "Версия сервера " + versionData.data.buildVersion + " от " + versionData.data.buildTime
          + " (rev " + versionData.data.buildRevision + ")"
    } else {
      this.version = "Версия сервера недоступна"
    }
    if (this.$store.getters.getToken === '') {
      this.$router.push("/signin")
      return;
    }
    let mapObject = this.$refs.map.mapObject;
    let context = this;

    mapObject.on('editable:drawing:end', function () {
      if (this.drawingMode === 'polygon') {
        if (!context.isPolygonEmpty) {
          context.showPolygonPopup();
        } else {
          context.clearPolygon();
        }
      } else if (this.drawingMode === 'point') {
        context.showPointPopup();
      }
    });
    mapObject.on("click", function () {
      context.clickedPoint = null;
      context.clickedPolygon = null;
      context.clickedDistance = null;
      context.clickedDistanceGeometry = null;
    });

  }
}
</script>

<style scoped>
@import '../assets/styles/map.css';
sup {
  line-height: 1 !important;
}

/deep/ .btn-group-toggle input[type="radio"] {
  display: none;
}

.map-container {
  height: 100vh;
}


.popup-button {
  display: block;
  width: 100%;
  height: 35px;
  padding: 8px 16px;
  text-align: center;
  border-radius: 50px;
  background-color: #17A2B8;
  color: white !important;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
}
.popup-button.disabled {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.12);
}

h4 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px !important;
  color: #7E8B96;
  display: flex;
  align-self: stretch;
}

.area-label > img {
  margin-right: 6px;
  margin-top: 3px;
}

.map-info {
  position: absolute !important;
  bottom: 16px !important;
  left: 50% !important;
  z-index: 1000 !important;
  width: 710px;
  transform: translate(-50%, 0);
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0.8);
  border: none !important;
  border-radius: 20px !important;
}

.popup-button.disabled {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.12);
}

.btn-building-primary {
  width:  230px;
  height:  48px;

  justify-content: center;
  align-items: center;
  padding: 12px 26px;
  gap: 12px;
  margin-right: 10px;

  background: #2D2D2D;
  color: #fefefe;
  backdrop-filter: blur(3.5px);
  border: 1px solid lightgray;
  border-radius: 12px;
}
.btn-building-load {
  width:  230px;
  height:  48px;
  justify-content: center;
  align-items: center;
  padding: 12px 26px;
  gap: 12px;
  background: #fefefe;
  color: #2D2D2D;
  backdrop-filter: blur(3.5px);
  border-radius: 12px;
  font-family: Mulish;
  font-size: 16px;
  border: 1px solid lightgray;
  margin-bottom: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.btn-logout {

  font-family: Mulish;
  background: #fefefe;
  color: #2D2D2D;
  backdrop-filter: blur(3.5px);
  border-radius: 12px;
  font-size: 12px;
  border: 1px solid lightgray;
  font-weight: bold;
}

.version-text {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
}

</style>
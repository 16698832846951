import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'leaflet/dist/leaflet.css';
import Toasted from 'vue-toasted';
import L from 'leaflet';
import router from './router'
import store from "@/storage";

Vue.config.productionTip = false

Vue.use(BootstrapVue)


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


Vue.use(Toasted, {
    duration: 5000,
    position: 'top-center',
    singleton: true,
});
const initializeApp = async () => {
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');
};

initializeApp();
import VueRouter from "vue-router";
import Map from "@/pages/Map";
import Vue from "vue";
import AuthorizationPage from "@/pages/AuthorizationPage";
import Editor from "@/pages/Editor";
import Result from "@/pages/Result";

Vue.use(VueRouter)

const router = new VueRouter({
    routes:  [
        {
            path: '/result/:resultId?',
            name:"result",
            component: Result,
            props: true,
            meta: {
                requiresPermissions: []
            }
        },{
            path: '/signin',
            component: AuthorizationPage
        },
        {
            path: '/editor',
            name:"editor",
            component: Editor,
            meta: {
                requiresPermissions: []
            }
        },
        {
            path: '/', component: Map,
            meta: {
                requiresPermissions: []
            }
        }
    ]
});

export default router